import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BaseForm,
  ChartViewConfig,
  ContentViewDto,
  CustomContentConfig,
  DataTypeEnum,
  getEnumOptions,
  getOptionsFromStringArray,
  getTableColumnDefinitionFromField,
  humanizeCasedString,
  IDynamicComponent,
  isNullObj,
  IViewMode,
  SearchFilterDto,
  StatisticViewConfig,
  TargetTypeEnum,
  ViewCard,
} from '@shared/classes';
import {
  chartVariationConditions,
  ChartVariationMapper,
} from '@shared/components/components/dashboard-items/dashlet-view/widget-chart/widget-chart.component';
import { IconSelectorComponent } from '@shared/components/selectors/icon-selector/icon-selector.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { BaseFieldDefinitionsService, PathResolverService, ViewModeService } from '@shared/services';
const chartTypesToExclude: (ChartViewConfig.ChartTypeEnum | StatisticViewConfig.ChartTypeEnum)[] = [
  ,
  'TIME_SCALE',
  'BAR_RACE',
  'LINE_RACE',
];
@Component({
  selector: 'app-view-item-form',
  templateUrl: './view-item-form.component.html',
  styleUrl: './view-item-form.component.scss',
})
export class ViewItemFormComponent extends BaseForm<ContentViewDto> implements OnInit {
  card: ViewCard;
  disabledTypes: ContentViewDto.TypeEnum[] = [
    'COMPLIANCE_REPORT',
    // 'CUSTOM',
    'ORG_CHART_LINK',
    'QUESTIONNAIRE_REPORT',
    'RELATION_CHART_LINK',
    'TIMELINE',
    'TREE_VIEW',
  ];
  removedTypes: ContentViewDto.TypeEnum[] = ['PAGE_BREAK'];
  reportTypeOptions = getEnumOptions(ContentViewDto.TypeEnum)
    .filter((x) => !this.removedTypes.includes(x.value))
    .map((x) => {
      return { ...x, disabled: this.disabledTypes.includes(x.value) };
    })
    .sort((a, b) => Number(a.disabled) - Number(b.disabled));
  targetTypeOptions = getEnumOptions(TargetTypeEnum);
  chartTypeItems = getEnumOptions(ChartViewConfig.ChartTypeEnum).filter((x) => !chartTypesToExclude.includes(x.value));
  // chartTypeOptions = getEnumOptions(ChartViewConfig.ChartTypeEnum).filter(
  //   (x) => !chartTypesToExclude.includes(x.value)
  // );
  statisticChartTypeOptions = getEnumOptions(StatisticViewConfig.ChartTypeEnum);
  chartViewTypeOptions = getEnumOptions(ChartViewConfig.ViewTypeEnum);
  // scaleTypeOptions = getEnumOptions(ChartViewConfig.ScaleTypeEnum);
  availableInOptions = getEnumOptions(ContentViewDto.AvailableInEnum);
  dataPositionOptions = getEnumOptions(ChartViewConfig.DataPositionEnum);
  customComponentTypeOptions = getEnumOptions(CustomContentConfig.ComponentTypeEnum);
  //table actions options
  statusActionsOptions = getOptionsFromStringArray(['Delete', 'Edit', 'Lock', 'Activate', 'Deactivate', 'UnLock']);
  operationsActionsOptions = getOptionsFromStringArray([
    'Add',
    'ReviseRelations',
    'FileExport',
    'PackageExport',
    'BulkOperation',
  ]);
  operationsControl = new FormControl(null);
  operationsControlChart = new FormControl(null);
  statusControl = new FormControl(null);
  statusControlChart = new FormControl(null);
  mappingService: BaseFieldDefinitionsService = null;
  linksFields: IDynamicComponent[] = [];
  _availableFields: any[];
  get availableFields() {
    return this._availableFields;
  }
  set availableFields(availableFields: any[]) {
    this._availableFields = availableFields;
    if (!isNullObj(availableFields))
      this.formRepeaterFields = [
        {
          componentType: DropDownInputComponent,
          options: {
            label: 'Field',
            name: 'fieldName',
            control: new FormControl(null, Validators.required),
            inputOptions: {
              dropDownInput: {
                multi: false,
                showClear: false,
                optionLabel: 'name',
                optionValue: 'key',
                items: this.availableFields,
                badgeView: false,
                appendTo: 'body',
              },
            },
            dataType: DataTypeEnum.Text,
            showLabelInViewMode: false,
          },
        },
        {
          componentType: DropDownInputComponent,
          options: {
            label: 'Sort Direction',
            name: 'sortOperation',
            control: new FormControl(null, Validators.required),
            inputOptions: {
              dropDownInput: {
                multi: false,
                showClear: false,
                optionLabel: 'label',
                optionValue: 'value',
                items: [
                  { label: 'Ascending', value: 'ASC' },
                  { label: 'Descending', value: 'DESC' },
                ],
                badgeView: false,
                appendTo: 'body',
              },
            },
            dataType: DataTypeEnum.Text,
            showLabelInViewMode: false,
          },
        },
      ];
  }
  formRepeaterFields: IDynamicComponent[] = [];
  constructor(
    public viewModeService: ViewModeService,
    private pathResolverService: PathResolverService
  ) {
    super(viewModeService, 'CONTENT_VIEW');
  }

  ngOnInit(): void {
    this.setLinksFields();
  }
  setLinksFields() {
    this.linksFields = [
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Title',
          name: 'title',
          control: new FormControl(null, Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
      {
        componentType: IconSelectorComponent,
        options: {
          label: 'Icon',
          name: 'icon',
          control: new FormControl(null),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          inputOptions: {
            dropDownInput: {
              multi: false,
              items: undefined,
              optionLabel: undefined,
              optionValue: undefined,
              appendTo: 'body',
            },
          },
        },
        viewModeOnly: false,
      },
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Link',
          name: 'link',
          control: new FormControl(null, [
            Validators.required,
            Validators.pattern(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i),
          ]),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    ];
  }
  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat(this.formatGetData())
      : this.getChangedFormValues(this.formatGetData()).updateItems;
  }

  setData(data: any) {
    this.onFilterChange(data?.filterView);
    this.onChangeTargetType(data?.targetType);
    this.patchFormData({ ...data });
    this.patchOperationControl(data);
    this.patchStatusControl(data);
    this.patchOperationControl(data, 'drillDownTableViewConfig', 'operationsControlChart');
    this.patchStatusControl(data, 'drillDownTableViewConfig', 'statusControlChart');
    this.data = data;
    //   this.formGroup?.patchValue({
    //     key: cleanNotAlphaNumericUnderScore(this.formGroup?.controls?.name?.value)
    //   })
  }
  formatGetData() {
    const ret: ContentViewDto = { ...this.formGroup?.getRawValue() };
    if (ret.type != 'TABLE') {
      ret.tableViewConfig = null;
    } else {
      ret.tableViewConfig.columns = ret.tableViewConfig.columns.map((x, i) => {
        return {
          ...x,
          order: i,
        };
      });
    }
    if (ret?.type == 'RECORD') {
      ret.recordField = ret?.recordField?.map((x) => {
        return {
          key: x.key,
          order: x.order,
          colSpan: x.colSpan,
        };
      });
    }
    return ret;
  }
  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      description: new FormControl(null),
      staticViewData: new FormControl(null),
      // displayConfig: new FormGroup({
      //     cols: new FormControl(null),
      //     rows: new FormControl(null),
      //     x: new FormControl(null),
      //     y: new FormControl(null),
      //     order: new FormControl(null),
      // }),
      type: new FormControl(null, Validators.required),
      targetType: new FormControl(null),
      filter: new FormControl(null),
      tableViewConfig: new FormGroup({
        columns: new FormControl(null),
        showGlobalFilter: new FormControl(false),

        showActions: new FormControl(false),

        showSettingsLink: new FormControl(false),

        showOperationActions: new FormControl(false),
        showFileExportAction: new FormControl(false),
        showPackageExportAction: new FormControl(false),
        showBulkOperationAction: new FormControl(false),
        showAddAction: new FormControl(false),
        showReviseRelationsAction: new FormControl(false),

        showStatusActions: new FormControl(false),
        showDeleteAction: new FormControl(false),
        showEditAction: new FormControl(false),
        showLockAction: new FormControl(false),
        showUnLockAction: new FormControl(false),
        showActivateAction: new FormControl(false),
        showDeactivateAction: new FormControl(false),
        sortFields: new FormControl(null),
      }),
      chartViewConfig: new FormGroup({
        chartType: new FormControl(null),
        colorPalette: new FormControl([
          '#c12e34',
          '#e6b600',
          '#0098d9',
          '#2b821d',
          '#005eaa',
          '#339ca8',
          '#cda819',
          '#32a487',
        ]),
        viewType: new FormControl('CHART_AND_DATA'),
        chartVariation: new FormControl('STANDARD'),
        // scaleType: new FormControl(null),
        dataPosition: new FormControl('DOWN'),
        isDrillDown: new FormControl(false),
        useSystemColors: new FormControl(true),
        drillDownAggregationField: new FormControl(null),
        panelSizes: new FormControl([50, 50]), //default  control not implemented
      }),
      statisticViewConfig: new FormGroup({
        collectorCode: new FormControl(null),
        viewType: new FormControl('CHART_AND_DATA'),
        useSystemColors: new FormControl(true),
        dataPosition: new FormControl('DOWN'),
        colorPalette: new FormControl([
          '#c12e34',
          '#e6b600',
          '#0098d9',
          '#2b821d',
          '#005eaa',
          '#339ca8',
          '#cda819',
          '#32a487',
        ]),
        chartType: new FormControl('TIME_SCALE'),
        chartVariation: new FormControl('LINE'),
        drillDownAggregationField: new FormControl(null),
      }),
      drillDownTableViewConfig: new FormGroup({
        columns: new FormControl(null),
        showGlobalFilter: new FormControl(false),

        showActions: new FormControl(false),

        showSettingsLink: new FormControl(false),

        showOperationActions: new FormControl(false),
        showFileExportAction: new FormControl(false),
        showPackageExportAction: new FormControl(false),
        showBulkOperationAction: new FormControl(false),
        showAddAction: new FormControl(false),
        showReviseRelationsAction: new FormControl(false),

        showStatusActions: new FormControl(false),
        showDeleteAction: new FormControl(false),
        showEditAction: new FormControl(false),
        showLockAction: new FormControl(false),
        showUnLockAction: new FormControl(false),
        showActivateAction: new FormControl(false),
        showDeactivateAction: new FormControl(false),
        sortFields: new FormControl(null),
      }),
      customViewConfig: new FormGroup({
        componentType: new FormControl('RISK_HEATMAP'),
      }),
      permissions: new FormControl(null),
      availableIn: new FormControl(['DASHBOARD', 'REPORT', 'TAB']),
      recordCode: new FormControl(null),
      recordField: new FormControl(null),
      linkFields: new FormControl(null),
      color: new FormControl(null),
      icon: new FormControl(null),
    });
  }
  get displayConfigForm() {
    return this.formGroup.controls.displayConfig as FormGroup;
  }
  get reportForm() {
    return this.formGroup;
  }
  get tableViewConfigForm() {
    return this.reportForm?.controls?.tableViewConfig as FormGroup;
  }
  get drillDownTableViewConfigForm() {
    return this.reportForm?.controls?.drillDownTableViewConfig as FormGroup;
  }
  get chartViewConfigForm() {
    return this.reportForm?.controls?.chartViewConfig as FormGroup;
  }
  get statisticViewConfigForm() {
    return this.formGroup?.controls?.statisticViewConfig as FormGroup;
  }
  get customViewConfigForm() {
    return this.formGroup?.controls?.customViewConfig as FormGroup;
  }
  drillDownAggregationFieldItems = [];
  currentFilterObj: SearchFilterDto;
  onFilterChange(filter) {
    this.currentFilterObj = filter;
    this.setFormattedColumns();
    this.drillDownAggregationFieldItems = this.currentFilterObj?.aggregationBody?.aggregationFields?.map((x) => {
      return {
        operation: x.operation,
        label: humanizeCasedString(x.fieldName),
        value: x.fieldName + '_' + x?.operation?.toLowerCase(),
      };
    });
    if (this.drillDownAggregationFieldItems?.length > 0)
      this.chartViewConfigForm?.controls?.drillDownAggregationField?.patchValue(
        this.drillDownAggregationFieldItems?.[0]?.value
      );
  }
  clearTableConfigColumns() {
    this.tableViewConfigForm?.controls?.columns?.patchValue(null);
  }
  clearFilterControl() {
    this.reportForm?.controls?.filter?.patchValue(null);
    this.tableViewConfigForm?.controls?.columns?.patchValue(null);
    this.onFilterChange(null);
  }
  onChangeTargetType(targetType) {
    if (isNullObj(targetType)) {
      this.mappingService = null;
      this.formattedRecordFields = [];
      this.formattedColumns = [];
      this.availableFields = [];
      return;
    }

    this.mappingService = this.pathResolverService.getMappingServiceByTargetType(targetType);
    this.setFormattedColumns();
    this.setFormattedRecordFields();
  }
  onViewTypeChange() {
    this.setFormattedColumns();
  }
  formattedColumns = [];
  setFormattedColumns() {
    if (isNullObj(this.currentFilterObj) || isNullObj(this.mappingService)) {
      this.formattedColumns = [];
      this.availableFields = [];
      return;
    }
    if (this.reportForm?.controls?.type?.value == 'CHART') {
      this.formattedColumns = this.mappingService.tableFields?.map((field, index) => {
        const colDef = field;
        return {
          key: colDef.key,
          name: colDef.name,
          order: index,
          visible: true,
          groupRowsBy: null,
          appliedFilters: null,
          sort: null,
        };
      });
    } else {
      this.formattedColumns = this.currentFilterObj?.filter?.projectionFields?.map((fieldKey, index) => {
        const colDef = getTableColumnDefinitionFromField(this.mappingService.mappedFields[fieldKey]);
        return {
          key: colDef.key,
          name: colDef.name,
          order: index,
          visible: true,
          groupRowsBy: null,
          appliedFilters: null,
          sort: null,
        };
      });
    }
    this.availableFields = this.formattedColumns;
  }
  onChangeFormattedColumn(key, state) {
    this.formattedColumns = this.formattedColumns.map((x) => {
      return { ...x, visible: x.key == key ? !state : x.visible };
    });
  }
  formattedRecordFields = [];
  setFormattedRecordFields() {
    if (isNullObj(this.mappingService)) return;
    this.formattedRecordFields = this.mappingService.tableFields.map((x, index) => {
      return {
        key: x.key,
        name: x.name,
        order: index,
        colSpan: 12,
        fullWidth: true,
      };
    });
  }
  onChangeFormattedRecord(key, state) {
    if (this.formattedColumns?.length > 0)
      this.formattedColumns = this.formattedColumns?.map((x) => {
        return { ...x, colSpan: x.key == key ? (!state ? 12 : 6) : x.colSpan };
      });
  }
  patchStatusControl(
    data,
    configFieldName: 'tableViewConfig' | 'drillDownTableViewConfig' = 'tableViewConfig',
    statucControlField: 'statusControl' | 'statusControlChart' = 'statusControl'
  ) {
    const statusPatchVal = [];
    data?.[configFieldName]?.showDeleteAction ? statusPatchVal.push('Delete') : null;
    data?.[configFieldName]?.showEditAction ? statusPatchVal.push('Edit') : null;
    data?.[configFieldName]?.showLockAction ? statusPatchVal.push('Lock') : null;
    data?.[configFieldName]?.showActivateAction ? statusPatchVal.push('Activate') : null;
    data?.[configFieldName]?.showDeactivateAction ? statusPatchVal.push('Deactivate') : null;
    data?.[configFieldName]?.showUnLockAction ? statusPatchVal.push('UnLock') : null;
    this?.[statucControlField].patchValue(statusPatchVal);
  }
  patchOperationControl(
    data,
    configFieldName: 'tableViewConfig' | 'drillDownTableViewConfig' = 'tableViewConfig',
    operationsControlField: 'operationsControl' | 'operationsControlChart' = 'operationsControl'
  ) {
    const operationPatchVal = [];
    data?.[configFieldName]?.showFileExportAction ? operationPatchVal.push('FileExport') : null;
    data?.[configFieldName]?.showPackageExportAction ? operationPatchVal.push('PackageExport') : null;
    data?.[configFieldName]?.showBulkOperationAction ? operationPatchVal.push('BulkOperation') : null;
    data?.[configFieldName]?.showAddAction ? operationPatchVal.push('Add') : null;
    data?.[configFieldName]?.showReviseRelationsAction ? operationPatchVal.push('ReviseRelations') : null;

    this?.[operationsControlField].patchValue(operationPatchVal);
  }
  onChangeStatusActions(values) {
    const patchVal = {
      showDeleteAction: false,
      showEditAction: false,
      showLockAction: false,
      showActivateAction: false,
      showDeactivateAction: false,
      showUnLockAction: false,
    };
    if (!isNullObj(values)) {
      values.forEach((element) => {
        patchVal['show' + element + 'Action'] = true;
      });
    }
    this.tableViewConfigForm.patchValue(patchVal);
  }
  onChangeOperationActions(values) {
    const patchVal = {
      showFileExportAction: false,
      showPackageExportAction: false,
      showBulkOperationAction: false,
      showAddAction: false,
      showReviseRelationsAction: false,
    };
    if (!isNullObj(values)) {
      values.forEach((element) => {
        patchVal['show' + element + 'Action'] = true;
      });
    }
    this.tableViewConfigForm.patchValue(patchVal);
  }
  onChangeDrilldownStatusActions(values) {
    const patchVal = {
      showDeleteAction: false,
      showEditAction: false,
      showLockAction: false,
      showActivateAction: false,
      showDeactivateAction: false,
      showUnLockAction: false,
    };
    if (!isNullObj(values)) {
      values.forEach((element) => {
        patchVal['show' + element + 'Action'] = true;
      });
    }
    this.drillDownTableViewConfigForm.patchValue(patchVal);
  }
  onChangeDrilldownOperationActions(values) {
    const patchVal = {
      showFileExportAction: false,
      showPackageExportAction: false,
      showBulkOperationAction: false,
      showAddAction: false,
      showReviseRelationsAction: false,
    };
    if (!isNullObj(values)) {
      values.forEach((element) => {
        patchVal['show' + element + 'Action'] = true;
      });
    }
    this.drillDownTableViewConfigForm.patchValue(patchVal);
  }
  get allowedFieldViewMode(): IViewMode {
    return this.showSaveAndClose ? this.fieldViewMode : 'view';
  }
  // get chartVariationOptions() {
  //   return ChartVariationMapper[this.chartViewConfigForm?.controls?.chartType?.value || 'DOUGHNUT'];
  // }
  get statisticChartVariationOptions() {
    return ChartVariationMapper[this.statisticViewConfigForm?.controls?.chartType?.value || 'DOUGHNUT'] || [];
  }
  getRealtimeData(): ContentViewDto {
    return {
      ...this.formGroup.getRawValue(),
      filterView: this.currentFilterObj,
    };
  }
  get chartTypeOptions() {
    const groupingLength = this.currentFilterObj?.aggregationBody?.groupByFields?.length;
    return this.chartTypeItems.filter(
      (x) =>
        this.getChartVariations(
          x.value,
          groupingLength == 2
            ? chartVariationConditions.THREE_D_ONLY
            : groupingLength >= 2
              ? chartVariationConditions.THREE_D
              : chartVariationConditions.TWO_D
        )?.length > 0
    );
  }
  get chartVariationOptions() {
    const grouingLength = this.currentFilterObj?.aggregationBody?.groupByFields?.length;
    return (
      this.getChartVariations(
        this.chartViewConfigForm?.controls?.chartType?.value || 'DOUGHNUT',
        grouingLength == 2
          ? chartVariationConditions.THREE_D_ONLY
          : grouingLength >= 2
            ? chartVariationConditions.THREE_D
            : chartVariationConditions.TWO_D
      ) || []
    );
  }
  getChartVariations(chartType, dimension) {
    return (
      ChartVariationMapper[chartType][dimension] ??
      ChartVariationMapper[chartType][chartVariationConditions.THREE_D] ??
      ChartVariationMapper[chartType][chartVariationConditions.TWO_D]
    );
  }
  onChangeChartType(value) {
    this.chartViewConfigForm?.controls?.chartVariation?.patchValue(this.chartVariationOptions?.[0]?.value);
  }
}
